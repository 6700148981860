import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Avatar from "./Avatar";
import cx from 'classnames';

const Testimonials = ({ testimonials }) => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        setItems(testimonials.map((t, i) => {
            t.id = i;
            t.active = i === 0;
            return t;
        }));
    }, []);

    const activate = (toActivate) => {
        setItems((prev) => prev.map(t => {
            t.active = t.id === toActivate
            return t;
        }));
    };

    return (
        <div className={"c-testimonials"}>
            {items.map(testimonial => (
                <article key={testimonial.id} className={cx("message", {active : testimonial.active})}>
                    <div className="message-body">
                        <span className={"copy-subheader copy-subheader--l"}>"{testimonial.quote}"</span>

                        <div className={"l-flex l-flex--center-middle"}>
                            <Avatar image={testimonial.image.publicURL}
                                    width={"75px"}
                                    alt={testimonial.author}/>
                            <cite> <b>/</b> <span className={"copy-secondary"}>{testimonial.author}</span></cite>
                        </div>
                    </div>
                </article>
            ))}

            <nav>
                {items.map((testimonial, i) => (
                    <span key={testimonial.id}
                          className={cx({active: testimonial.active})}
                          onClick={() => activate(i)}></span>
                ))}
            </nav>
        </div>
    )
}

Testimonials.propTypes = {
    testimonials: PropTypes.arrayOf(
        PropTypes.shape({
            quote: PropTypes.string,
            author: PropTypes.string,
        })
    ),
}

export default Testimonials
